import React from "react";
import styles from "./home.module.css";

export default function Home() {
  return (
    <div className={styles.center}>
      <img
        className={styles.logo}
        src={require("../images/logo_2.png")}
        alt="logo"
      />
    </div>
  );
}

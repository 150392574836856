import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Template from "./pages/natal/template";
import TemplateSvy from "./pages/natal-svy/template";
import Home from "./pages/home";
import Circo from "./pages/circo/template";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/natal/:guid" element={<Template />} />
        <Route path="/natal-svy/:guid" element={<TemplateSvy />} />
        <Route path="/circo" element={<Circo />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;

import { useEffect, useState } from "react";
import axios from "axios";
import styles from "./template.module.css";

function Circo() {
  const [adults, setAdults] = useState(0);
  const [babies, setBabies] = useState(0);
  const [childs, setChilds] = useState(0);
  const [childs2, setChilds2] = useState(0);

  const [name, setName] = useState("");
  const [code, setCode] = useState("");
  const [company, setCompany] = useState("AFAVIAS / CTM");
  const [session, setSession] = useState("07/12 às 21:00h");

  const [confirm, setConfirm] = useState(false);

  const [price, setPrice] = useState(0);

  const [listTshirts, setListTshirts] = useState([
    {
      name: "Tamanho 1 ano",
      qty: 0,
    },
    {
      name: "Tamanho 2 anos",
      qty: 0,
    },
    {
      name: "Tamanho 3 anos",
      qty: 0,
    },
    {
      name: "Tamanho 4 anos",
      qty: 0,
    },
    {
      name: "Tamanho 5 anos",
      qty: 0,
    },
    {
      name: "Tamanho 6 anos",
      qty: 0,
    },
    {
      name: "Tamanho 7 anos",
      qty: 0,
    },
    {
      name: "Tamanho 8 anos",
      qty: 0,
    },
    {
      name: "Tamanho 9 anos",
      qty: 0,
    },
    {
      name: "Tamanho 10 anos",
      qty: 0,
    },
    {
      name: "Tamanho 11 anos",
      qty: 0,
    },
  ]);

  async function incrementCount(val, incr) {
    incr(val + 1);
  }

  async function dincrementCount(val, incr) {
    if (val > 0) {
      incr(val - 1);
    }
  }

  useEffect(() => {
    const adultPrice = adults * 5;
    const childs2Price = childs2 * 5;
    setPrice(adultPrice + childs2Price);
  }, [adults, childs2]);

  //communication/attend/

  const submit = () => {
    const data = {
      name: name,
      code: code,
      price: price.toString(),
      company: company,
      session: session,
      list: {
        Adultos: adults,
        Bebes: babies,
        CriancasColab: childs,
        OutrasCriancas: childs2,
        Tshirts: listTshirts,
      },
    };
    axios
      .post(
        "https://solmant.savoysignature.com:8001/communication/attendance/CRC0654161",
        data
      )
      .then(() => {
        setConfirm(true);
      });
  };

  function YourComponent() {
    function incrementTshirt(index) {
      const updatedTshirts = [...listTshirts];
      updatedTshirts[index].qty += 1;
      setListTshirts(updatedTshirts);
    }

    function decrementTshirt(index) {
      const updatedTshirts = [...listTshirts];
      if (updatedTshirts[index].qty > 0) {
        updatedTshirts[index].qty -= 1;
        setListTshirts(updatedTshirts);
      }
    }

    return (
      <div className={styles.columns}>
        {listTshirts.map((tshirt, index) => (
          <div key={index} className={styles.rowContent}>
            <p>{tshirt.name}</p>
            <div className={styles.counter}>
              <div
                className={styles.btnCounter}
                onClick={() => decrementTshirt(index)}
              >
                -
              </div>
              <div>{tshirt.qty}</div>
              <div
                className={styles.btnCounter}
                onClick={() => incrementTshirt(index)}
              >
                +
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }

  return (
    <div className={styles.temp}>
      {/* <div className={styles.loading}>
        <img src={require("../../images/logo_3.png")} alt="logo" />
      </div> */}

      <div className={styles.container}>
        <div className={styles.logo}>
          <img src={require("../../images/logo.png")} alt="logo" />
        </div>

        {confirm === true ? (
          <>
            <div className={styles.box}>
              <h2>{name}</h2>
              <p>{company}</p>
              <p className={styles.n}>
                <p>{code}</p>
                <div className={styles.submited}>Submetido</div>
              </p>
              <div className={styles.separator}></div>
              <p>
                <b>{session}</b>
              </p>
              <div className={styles.separator}></div>
              <ul>
                <li>
                  <p>Adultos</p>
                  <div className={styles.doted}></div>
                  <p>{adults}</p>
                </li>
                <li>
                  <p>Crianças até 3 anos</p>
                  <div className={styles.doted}></div>
                  <p>{babies}</p>
                </li>
                <li>
                  <p>Filhos de colaboradores até 10 anos</p>
                  <div className={styles.doted}></div>
                  <p>{childs}</p>
                </li>
                <li>
                  <p>Outras crianças até 10 anos</p>
                  <div className={styles.doted}></div>
                  <p>{childs2}</p>
                </li>
              </ul>

              {listTshirts.filter((e) => e.qty > 0).length !== 0 ? (
                <>
                  <div className={styles.separator}></div>
                  <p>
                    <b>T-shirts</b>
                  </p>
                </>
              ) : (
                ""
              )}
              <ul>
                {listTshirts
                  .filter((e) => e.qty > 0)
                  .map((e) => (
                    <li>
                      <p>{e.name}</p>
                      <div className={styles.doted}></div>
                      <p>{e.qty}</p>
                    </li>
                  ))}
              </ul>
              <div className={styles.separator}></div>
              <div className={styles.price}>
                <h1>{price !== 0 ? price + ",00 €" : ""}</h1>
              </div>
              <br />
              <p style={{ fontWeight: "bold" }}>
                {" "}
                NOTA: Para concluir e validar esta inscrição, deverá efetuar o
                pagamento nos escritórios do PEZO (Secretariado) ou Recursos
                Humanos da Savoy Signature (Nicola Tranquada).
              </p>
            </div>
          </>
        ) : (
          <>
            <div className={styles.box}>
              <h3>Inscrição Festa Natal Crianças - Circo 2023</h3>
              <p>Todas as crianças dos 0 - 3 anos: Grátis</p>
              <p>Filhos de colaboradores dos 4 - 10 anos: Oferta</p>
              <p>Adultos e outras crianças: 5€</p>
              <span>
                Contacto: AFAVIAS 291 911 010 | SAVOY 291 213 000 |
                comiteempresa@afa.pt
              </span>
            </div>

            <div className={styles.box}>
              <h4>Nome</h4>
              <div className={styles.boxSelect}>
                <input
                  type="text"
                  onChange={(value) => setName(value.target.value)}
                />
              </div>
              <h4>Número de colaborador</h4>
              <div className={styles.boxSelect}>
                <input
                  type="text"
                  onChange={(value) => setCode(value.target.value)}
                />
              </div>
              <h4>Empresa</h4>
              <div className={styles.boxSelect}>
                <select onChange={(value) => setCompany(value.target.value)}>
                  <option defaultValue value={"AFAVIAS / CTM"}>
                    AFAVIAS / CTM
                  </option>
                  <option value={"Savoy Palace"}>Savoy Palace</option>
                  <option value={"Saccharum"}>Saccharum</option>
                  <option value={"Calheta Beach"}>Calheta Beach</option>
                  <option value={"Gardens"}>Gardens</option>
                  <option value={"Royal Savoy"}>Royal Savoy</option>
                  <option value={"NEXT"}>NEXT</option>
                  <option value={"Vialitoral"}>Vialitoral</option>
                  <option value={"Viaexpresso"}>Viaexpresso</option>
                </select>
              </div>
              <h4>Seleccione a sessão pretendida</h4>
              <div className={styles.boxSelect}>
                <select onChange={(value) => setSession(value.target.value)}>
                  <option defaultValue value={"28/12 às 21h00"}>
                    28/12 às 21h00
                  </option>
                </select>
              </div>
            </div>

            <div className={styles.box}>
              <div className={styles.columns}>
                <div className={styles.rowContent}>
                  <p>Adultos</p>
                  <div className={styles.counter}>
                    <div
                      className={styles.btnCounter}
                      onClick={() => dincrementCount(adults, setAdults, true)}
                    >
                      -
                    </div>
                    <div>{adults}</div>
                    <div
                      onClick={() => incrementCount(adults, setAdults, true)}
                      className={styles.btnCounter}
                    >
                      +
                    </div>
                  </div>
                </div>
                <div className={styles.rowContent}>
                  <p>Crianças até 3 anos</p>
                  <div className={styles.counter}>
                    <div
                      className={styles.btnCounter}
                      onClick={() => dincrementCount(babies, setBabies)}
                    >
                      -
                    </div>
                    <div>{babies}</div>
                    <div
                      onClick={() => incrementCount(babies, setBabies)}
                      className={styles.btnCounter}
                    >
                      +
                    </div>
                  </div>
                </div>
                <div className={styles.rowContent}>
                  <p>Filhos de colaboradores até 10 anos</p>
                  <div className={styles.counter}>
                    <div
                      className={styles.btnCounter}
                      onClick={() => dincrementCount(childs, setChilds)}
                    >
                      -
                    </div>
                    <div>{childs}</div>
                    <div
                      onClick={() => incrementCount(childs, setChilds)}
                      className={styles.btnCounter}
                    >
                      +
                    </div>
                  </div>
                </div>

                <div className={styles.rowContent}>
                  <p>Outras crianças até 10 anos</p>
                  <div className={styles.counter}>
                    <div
                      className={styles.btnCounter}
                      onClick={() => dincrementCount(childs2, setChilds2)}
                    >
                      -
                    </div>
                    <div>{childs2}</div>
                    <div
                      className={styles.btnCounter}
                      onClick={() => incrementCount(childs2, setChilds2)}
                    >
                      +
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.box}>
              <p>
                <b>Indique o tamanho e quantidade de t-shirts pretendidas</b>
              </p>
              <br />
              <YourComponent />
            </div>

            <div className={styles.boxBtn}>
              <button
                disabled={
                  name !== "" &&
                  code !== "" &&
                  adults + childs + childs2 + babies !== 0
                    ? false
                    : true
                }
                onClick={() => submit()}
              >
                Confirmar
              </button>
            </div>
          </>
        )}

        <footer></footer>
      </div>
    </div>
  );
}

export default Circo;
